@import 'variables';
@import 'mixins';

// font awesome icons
.faIconDARK,
.faIconSmDARK,
.faIconLIGHT,
.faIconSmLIGHT {
	&:hover {
		color: $peach;
	}
}

.faIconSmDARK,
.faIconSmLIGHT {
	font-size: 15px;
}

.faIconDARK {
	cursor: pointer;
	font-size: 30px;
	margin-right: 20px;
	@include bp(xs) {
		font-size: 25px;
	}
}

.faIconLIGHT {
	cursor: pointer;
	color: $grey;
	font-size: 30px;
	margin-right: 20px;
	@include bp(xs) {
		font-size: 25px;
	}
}

// project page icons
.iconWrap {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.techIconWrap {
	margin-top: 30px;
	justify-content: flex-start;
	display: flex;
	@include bp(xs) {
		margin-top: 0;
	}
}

.techIcon {
	padding: 7px;
	width: 100px;
	text-align: center;
	margin-right: 20px;
	border-radius: 0.5em;
}

.techIconLIGHT {
	color: $grey;
	border: 0.5px solid $lightGrey-t;
}

.techIconDARK {
	color: $white;
	border: 0.5px solid $spotGrey-t;
}

.projectIcon {
	color: $dualGrey;
	font-size: 40px;
	margin-right: 30px;
	@include bp(lg) {
		margin-right: 20px;
		margin-left: 0;
	}
	@include bp(sm) {
		font-size: 26px;
	}
	@include bp(xs) {
		font-size: 25px;
		margin-right: 15px;
	}
}

.gridIcon {
	color: $lightGrey;
	cursor: pointer;
	font-size: 30px;
}

.prevIcon,
.nextIcon {
	color: $lightGrey;
	cursor: pointer;
	font-size: 30px;
}

.prevIcon:hover,
.nextIcon:hover,
.gridIcon:hover {
	color: $spotGrey;
}

// footer icons
.footerIcon {
	font-size: 22px;
	margin-top: 13px;
}

// scroll up icon
.scrollUpIconDARK {
	background: $none !important;
	svg {
		fill: $peach !important;
	}
	:hover {
		fill: $yellow !important;
	}
}

.scrollUpIconLIGHT {
	background: $none !important;
	svg {
		fill: $peach !important;
	}
	:hover {
		fill: $blue !important;
	}
}

// image theme (rgb) icon
.imgThemeIcon {
	cursor: pointer;
	height: 25px;
	margin-left: 13px;
	&:hover {
		opacity: 0.5;
	}
}

// app theme toggle button
.appTheme {
	touch-action: pan-x;
	display: inline-block;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	margin-left: 13px;
	border: 0;
	padding: 0;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: $none;
	-webkit-tap-highlight-color: transparent;
	&:hover .appThemeCircle {
		border: 2px solid $peach;
	}
	@include bp(md) {
		margin-left: 0;
	}
}

.appThemeInput {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.appThemeCheck,
.appThemeUncheck {
	position: absolute;
	width: 10px;
	height: 10px;
	top: 0;
	bottom: 5px;
	margin-top: 7px;
	margin-bottom: auto;
	line-height: 0;
	opacity: 0;
	transition: opacity 0.25s ease;
}

.appThemeCheck {
	left: 8px;
}

.appThemeUncheck {
	opacity: 1;
	right: 10px;
}

.appThemeUncheck span,
.appThemeCheck span {
	align-items: center;
	display: flex;
	height: 10px;
	justify-content: center;
	position: relative;
	width: 10px;
}

.appThemeContainer {
	width: 50px;
	height: 24px;
	padding: 0;
	border-radius: 30px;
	background-color: $medGrey;
	transition: all 0.2s ease;
}

.appThemeCircle {
	transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	position: absolute;
	top: 1px;
	left: 1px;
	width: 22px;
	height: 22px;
	border: 2px solid $medGrey;
	border-radius: 50%;
	background-color: $white;
	box-sizing: border-box;
	transition: all 0.25s ease;
}

.appThemeChecked .appThemeCheck {
	opacity: 1;
}

.appThemeChecked .appThemeUncheck {
	opacity: 0;
}

.appThemeChecked .appThemeCircle {
	left: 27px;
}

.mobileAppTheme {
	display: none;
	visibility: hidden;
	@include bp(md) {
		display: inline-block;
		visibility: visible;
	}
}
