// variables

// main
$white: #ffffff;
$black: #232323;

// transparent
$none: rgba(0, 0, 0, 0);
$lightGrey-t: rgba(21, 21, 21, 0.35);
$spotGrey-t: rgba(138, 138, 138, 0.35);

// colours
$blue: #65b1fc;
$grey: #242424;
$peach: #ff6666;
$pink: #ff99ff;
$purple: #d279fc;
$yellow: #ffda45;
$teal: #3b9c67;

// light colours
$lightPeach: #ffa7a7;
$lightBlue: #9ccaf9;
$lightYellow: #fde894;
$lightGrey: #616161;

// grey variations
$spotGrey: #b6b6b6;
$medGrey: #4d4d4d;
$dualGrey: #949494;

// weather icon colours
$color-sun: #edc951;
$color-cloud: #00a0b0;
$color-cloud-light: #00cee3;
$color-cloud-dark: #008997;
$color-rain-cloud: #666;
$color-rain-cloud-light: #999;
$color-rain-cloud-dark: #555;
$color-snow-cloud: #ccc;
$color-main-background: #222;
$color-rainbow-red: #bd1e52;
$color-rainbow-red: #bd1e52;
$color-rainbow-orange: #e88024;
$color-rainbow-yellow: #f8cb10;
$color-rainbow-green: #899c3b;

// main font
$font: 'Roboto', sans-serif;

// font weights
$thin: 100;
$light: 300;
$regular: 400;
$bold: 700;

// transitions
$speed: all 0.2s ease-in-out;
