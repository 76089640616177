@import 'variables';
@import 'mixins';

// image styles
.imgWrap {
	padding: 5px;
	cursor: pointer;
	height: auto;
	width: 100%;
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	&:hover {
		-webkit-filter: grayscale(0%);
		filter: grayscale(0%);
	}
}

.imgWrapActive {
	-webkit-filter: grayscale(0%);
	filter: grayscale(0%);
}
