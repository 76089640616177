@import 'variables';
@import 'mixins';

// main text colours
.textWhite {
	color: $white;
}

.textBlack {
	color: $black;
}

// theme text colours
.textDARK {
	color: $white;
}

.textLIGHT {
	color: $grey;
}

.textMedDARK {
	color: $white;
}

.textMedLIGHT {
	color: $lightGrey;
}

.textFlipDARK {
	color: $spotGrey;
}

.textFlipLIGHT {
	color: $grey;
}

.textSpotDARK {
	color: $spotGrey;
}

.textSpotLIGHT {
	color: $lightGrey;
}

// text colours
.textBlue {
	color: $blue;
}

.textGrey {
	color: $grey;
}

.textPeach {
	color: $peach;
}

.textPink {
	color: $pink;
}

.textPurple {
	color: $purple;
}

.textYellow {
	color: $yellow;
}

.textTeal {
	color: $teal;
}

// light text colours
.textLightGrey {
	color: $lightGrey;
}

.textSpotGrey {
	color: $spotGrey;
}

.textDualGrey {
	color: $dualGrey;
}

// custom text styles

// project text on project page
.projectText {
	font-weight: $light;
	line-height: 1.5em;
	letter-spacing: 0.7px;
	font-size: 1.2em;
}

// this text in type section
.textThis {
	color: $peach;
	font-style: italic;
	font-weight: $bold;
	letter-spacing: 1px;
	margin-bottom: 5px;
}

// more fewer text in gallery section
.moreFewerLIGHT {
	color: $blue;
	text-align: center;
	margin-top: 10px;
	cursor: pointer;
	&:hover {
		color: $peach;
	}
}

.moreFewerDARK {
	color: $yellow;
	text-align: center;
	margin-top: 10px;
	cursor: pointer;
	&:hover {
		color: $peach;
	}
}

// text weights
.text100 {
	font-weight: $thin;
}

.text300 {
	font-weight: $light;
}

.text400 {
	font-weight: $regular;
}

.text700 {
	font-weight: $bold;
	letter-spacing: 0.5px;
}

// text styles and alignments
.textItalic {
	font-style: italic;
}

.textCenter {
	text-align: center;
}

.textRight {
	text-align: right;
	@include bp(xl) {
		margin-top: 15px;
	}
	@include bp(lg) {
		margin-bottom: 15px;
		text-align: left;
	}
}

// responsive text styles

// footer (ARCHIVED SITES)
.responsiveText {
	@include bp(lg) {
		text-align: left;
	}
	@include bp(md) {
		margin-bottom: 0;
	}
}
