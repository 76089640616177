@import 'variables';
@import 'mixins';

// reset
* {
	@include reset(0, 0, border-box, none, none);
	font-family: $font;
}

// body
html,
body {
	height: 100%;
	scrollbar-color: $lightPeach $peach;
}

body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	color: $grey;
}

// background colours
.bgLIGHT {
	background: $white;
}

.bgDARK {
	background: $black;
}

// scroll
.scroll {
	overflow: hidden;
}

::-webkit-scrollbar {
	background: $peach;
}

::-webkit-scrollbar-thumb {
	background: $lightPeach;
}

// code
code {
	font-family: $font;
}

// typography and links
p {
	font-weight: $light;
	line-height: 1.7em;
	letter-spacing: 0.7px;
}

a {
	letter-spacing: 0.7px;
}

h1,
h2,
h3,
h4,
li,
i,
a,
svg,
input,
img,
.brand {
	@include transitions($speed, $speed, $speed, $speed);
}

h1,
h2 {
	font-weight: $bold;
	letter-spacing: 0.8px;
}

h3,
h4,
h5 {
	font-weight: $regular;
	letter-spacing: 0.5px;
}

h1 {
	@include bp(xs) {
		font-size: 1.4em;
	}
}

h2 {
	@include bp(xs) {
		font-size: 1.1em;
		line-height: 1.7em;
	}
}

// selection
::selection {
	color: $peach;
}

::-moz-selection {
	color: $peach;
}

// positions
.positionRelative {
	position: relative;
}

// flex
.flex {
	display: flex;
	flex-direction: row;
}

.flexFooter {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.wrap {
	justify-content: space-between;
}

.wrapStart {
	justify-content: flex-start;
}

.wrapEnd {
	justify-content: flex-end;
}

// lines
.line {
	border-bottom: 4px solid $lightGrey;
	margin: 20px 5px;
}

.lineNoMar {
	border-bottom: 4px solid $lightGrey;
	margin-bottom: 10px;
	margin: 10px 5px;
}

.lineThin {
	border-bottom: 1px solid $lightGrey;
	margin-bottom: 10px;
	margin-top: 10px;
	@include bp(md) {
		margin-top: 0;
	}
}

.lineThinFooter {
	border-bottom: 1px solid $lightGrey;
	@include bp(md) {
		margin-top: 10px;
	}
}

.lineThinAbout {
	border-bottom: 1px solid $lightGrey;
	margin: 15px 0 15px 0;
}

// padding
.pad {
	padding: 5px;
}

.padLeft {
	padding-left: 0;
}

.padRight {
	padding-right: 0;
}

// margin
.mar {
	margin: 15px auto;
}

.marTop {
	margin-top: 20px;
}

.marBot {
	margin-bottom: 10px;
}

.marBot20 {
	margin-bottom: 20px;
}

.marLeft {
	margin-left: 10px;
}

.marBotProject {
	margin-bottom: 20px;
}

.marBotFooter {
	margin-bottom: 5px;
}

.mar50 {
	margin-top: 50px;
	@include bp(xs) {
		margin-top: 25px;
	}
}

// alignment
.align {
	align-items: center;
}

// visibility
.visible {
	visibility: visible;
	display: block;
}

.hidden {
	visibility: hidden;
	display: none;
}
