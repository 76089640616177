@import 'variables';
@import 'mixins';

.loader {
	background: $grey;
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: center;
  &Inner {
    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
  }
  &LineWrap {
    animation: spin 2000ms cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite;
    box-sizing: border-box;
    height: 50px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transform-origin: 50% 100%;
    width: 100px;
  }
}

.loaderLine {
	border: 4px solid transparent;
	border-radius: 100%;
	box-sizing: border-box;
	height: 100px;
	left: 0;
	margin: 0 auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 100px;
}

.loaderLineWrap {
  &:nth-child(1) {
    animation-delay: -50ms;
    & .loaderLine {
      border-color: $peach;
      height: 90px;
      width: 90px;
      top: 7px;
    }
  }
  &:nth-child(2) {
    animation-delay: -100ms;
    & .loaderLine {
      border-color: $yellow;
      height: 76px;
      width: 76px;
      top: 14px;
    }
  }
  &:nth-child(3) {
    animation-delay: -150ms;
    & .loaderLine {
      border-color: $pink;
      height: 62px;
      width: 62px;
      top: 21px;
    }
  }
  &:nth-child(4) {
    animation-delay: -200ms;
    & .loaderLine {
      border-color: $teal;
      height: 48px;
      width: 48px;
      top: 28px;
    }
  }
  &:nth-child(5) {
    animation-delay: -250ms;
    & .loaderLine {
      border-color: $blue;
      height: 34px;
      width: 34px;
      top: 35px;
    }
  }
}

@keyframes spin {
	0%,
	15% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
